body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.booking-detail .MuiFormControl-root {
  flex-direction: row;
}

.booking-detail .MuiInputLabel-root {
  min-width: 130px;
  margin-top: 11px;
  white-space: nowrap;
  font-weight: bold;
  color: black;
}

.MuiGrid-item {
  padding-top: inherit !important;
  margin-top: 64px !important;
}

html,
body,
#root {
  height: 100%;
}
